
:root {
    --header-size: 90px;
    --component-height: calc(100vh - var(--header-size));
}

body {
    color: var(--font-color);
}
html {
    scroll-behavior: smooth;
}
.land {
    margin-top: 200px;
    display: flex;
    justify-content: space-around;
    gap: 50px;

    div{
        width: 45vw;
    }

    .image {
        background-image: url('/home/page.png');
        background-repeat: no-repeat;
        background-size: 100vw;
        img {
            width: 100%;
        }

    }

    .presentation {
        padding: 100px 50px;
        h3 {
            font-size: 50pt;
            color: var(--primary-color);
        }

        p {
            font-size: 20pt;
        }

        span {
            color: var(--primary-color);
        }
    }
}

.features-container {
    background-color: white;
    display: flex;
    justify-content: space-evenly;
    padding: 35px 0;
    .title-container {
        display: flex;
        align-items: center;
        h2 {
            font-size: 30px;
            font-weight: 500;
        }
        .feature-item{
            margin-top: 25px;
            line-height: 20px;
            p {
                font-size: 17px;
            }
        }
    }

    .grid-container{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 25px;
        .grid-item{
            width: 300px;
            height: 300px;
            text-align: center;
            .feature {
                padding-left: 15px;
                padding-right: 15px;
                &.inverse {
                    background-color: var(--primary-color);
                    box-shadow: 1px 1px 2px white;
        
                    .icon, h4, p {
                        color: white;
                    }
                    h4 {
                        font-size: 20px;
                    }
                    p {
                        margin-top: 20px;
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
                &.inverse:hover {
                    background-color: white;
                    box-shadow: 1px 1px 2px var(--primary-color);
        
                    .icon {
                        color: var(--primary-color);
                    }
                    h4 {
                        color: var(--primary-color);
                    }
                    p {
                        color: black;
                        font-weight: normal;
                    }
                    transition: all ease-in-out 0.3s;
                }
        
        
                background: white;
                border-radius: 10px;
                width: 300px;
                height: 300px;
                padding-block: 20%;
                box-shadow: 1px 1px 5px var(--primary-color);
                .icon{
                    color: var(--primary-color);
                    font-size: 50pt;
                }
                h4 {
                    font-size: 20px;
                    font-weight: 500;
                }
                p {
                    font-size: 16px;
                }
                .row {
                    display: flex;
                    gap: 20px;
                }
            }
        }
    }

    #about {
        position: absolute;
        top: 0;
        z-index: -1;
        width: 100vw;
        height: 100vh;
    }

}
