.subHeader {
    background-color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 90px;
    .menu-left{
        padding: 55px;
        animation: fadeIn 2000ms ease-in-out forwards;
        h2{
            font-size: 30px;
            font-weight: 500;
        }
        .features{
            padding: 15px 55px;
            .sub-features{
                display: flex;
                gap: 55px;
            
            }
            span {
                font-size: 18px;
                display: flex;
                gap: 15px;
            }
        }
        .action {
            display: flex;
            width: fit-content;
            gap: 20px;
            margin-top: 30px;
        }
    }
    .img-bg{
        height: 750px;
        animation: fadeIn 2000ms ease-in-out forwards;
    }
}

