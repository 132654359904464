.my-sidebar {
    min-height: 100vh !important;
    position: fixed;
    top: 0;
    left: 0;

    .logo {
        font-size: xx-large;
        color: var(--font-color);
        
        img {
            height: auto !important;
            object-fit: cover;
        }
    }

    .nav {
        margin-top: 70px;
    }

    .sidebar-brand {
        margin-top: auto !important;
        img {
            object-fit: cover;
        }
        
    }

    
}

