

.members-list-container {
    h1 {
        span {
            color: var(--primary-color);
        }
    }

    .member-card-loader {
        height: 280px;
        border-radius: 5px;
        line-height: 10px;

        div {
            border-radius: 5px;
        }

        .body {
            padding: 10px 20px;
            height: 280px;

            .member-information {
                .member-image {
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    font-size: 60px; 
                    color: var(--font-color);
                }
    
                .member-name-phone {
                    margin-top: 17px;
                    margin-left: 5px;
                    .name{
                        width: 75%;
                        height: 22px;
                        background: var(--loader-gray-color);
                        margin-bottom: 5px;
                    }
                    .profil {
                        width: 25%;
                        height: 19px;
                        background: var(--loader-gray-color);
                    }
                }
            }
            .other {
                div {
                    height: 25px;
                    margin-bottom: 2px;
                    background-color: var(--loader-gray-color);
                }
                .mail {
                    width: 50%;
                }
                .facebook {
                    width: 45%;
                }
                .phone{
                    width: 30%;
                }
                .roles {
                    margin-bottom: 20px;
                    height: 50px;
                }
            }
        }
        .footer {
            display: flex;
            justify-content: flex-end;
            padding: 10px;
        }
    }

    .member-card {
        line-height: 10px;
        
        .body {
            padding: 10px 20px;
            height: 280px;

            .member-information {
                .member-image {
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    font-size: 60px; 
                    color: var(--font-color);
                }
    
                .member-name-phone {
                    margin-top: 17px;
                    margin-left: 5px;
                    .phone{
                        cursor: auto;
                    }
                }
                h4 {
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            a {
                color: var(--link-color);
                cursor: pointer;
            }
            .roles {
                width: 100%;
                overflow-y: scroll;
                padding-bottom: 5px;
                height: 50px;

                .role-badge {
                    padding: 7px 15px;
                    font-size: 0.8rem;
                }
            }
        }
        .footer {
            display: flex;
            justify-content: flex-end;
            padding: 10px;
        }
    }

    .member-card:hover {
        text-decoration: none;
    }
}

.new-member__modal {
    padding: auto;
    button {
        margin-inline: 25%;
    }
}

.delete-member__modal {
    .delete-body {
        p {
            span {
                color: var(--danger-color);
            }
        }
    }
}