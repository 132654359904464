
.offer-container {
    position: relative;
    height: 100vh;
    
    .carousel-container {
        width: 100vw;
        height: var(--component-height);
        position: absolute;
        top: 0;
        
        .carousel-item {
            width: 100vw;
            height: 100vh;
            
            p {
                color: white;

            }
            img {
                width: 100vw;
                height: 100vh;
            }
        }
        
        .carousel-control-prev,
        .carousel-control-next,
        .carousel-indicators {
            display: none;
        }
    }
}
.offers {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    gap: 50px;
    .offer__card {
        width: 25vw;
        height: 600px;
        max-width: 350px;
        min-width: 250px;
        background-color: white;
        opacity: 0.90;
        overflow: hidden;
        border-radius: 10px;
        position: relative;
        .header {
            background-color: var(--primary-color);
            text-align: center;
            h2 {
                color: var(--font-color-light);
                font-size: 22px;
                padding: 15px;
            }
        }
    
        .body {
            padding: 20px;
            margin-bottom: 50px;
            ul {
                list-style: none;
                li {
                    margin-bottom: 6px;
                }
    
                li:last-child {
                    margin-bottom: 0;
                }
            }
        }
    
        .footer {
            background-color: transparent;
            display: flex;
            justify-content: center;
            width: 100%;
            position: absolute;
            padding: 20px 10px;
            bottom: 0;
    
            button {
                padding: 10px 40px;
            }
        }
    }
}