:root {
  --primary-color: #4709c2;
  --secondary-color: #475252; 
  --warning-color: #FFC107; 
  --danger-color: #DC3545; 
  --danger-color-disabled: #d56873; 
  --success-color: green; 
  --danger-color-disabled: #d56873; 
  --primary-color-darker: #499e89;
  --font-color: #172b4D;
  --font-color-gray: rgb(134, 125, 125); 
  --font-color-light: white; 
  --neutral-color: #F8F9FA;
  --bs-primary: var(var(--primary-color));
  --border-color: #e4d8d8;
  --link-color: #5184cf;
  --loader-gray-color: #F5F5F5;
  --primary-color-disabled: #BBA3F1;
  --bg-task-card: white;
  --bg-task-column: #f6f8fc;
  --bg-task-button-hover: #edf2fa;
  --bg-button-task-hover: #dbe4eb;
  --primary-color-disabled: #BBA3F1;
}

$primary: var(--primary-color);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.is-invalid .form-control-feedback {
  display: none;
}

.form-error {
  color: var(--danger-color);
}

.form-has-error {
  border-color: var(--danger-color);
  box-shadow: 0 0 0 1px var(--danger-color);
  border-radius: 2px;
}

.asako-btn {
  border-radius: 5px;
  padding: 5px 30px;
  border: 1px solid;
}

.asako-btn-icon {
  padding: 5px 15px;
  border: 1px solid;
  border-radius: 5px;
}

.asako-btn-primary {
  background: var(--primary-color);
  border: none;
  color: var(--font-color-light);
  &:not(:disabled):hover {
    background: white;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    transition: all 0.5s ease-in-out;
  }
  &:disabled {
    opacity: 0.7;
    background-color: (var(--primary-color-disabled));
  }
}

.asako-btn-primary-reverse {
  background: white;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  &:not(:disabled):hover {
    background: var(--primary-color);
    border-color: white;
    color: white;
    transition: all 0.5s;
  }
  &:disabled {
    opacity: 0.7;
    background-color: white;
  }
}



.asako-btn-secondary {
  background: var(--secondary-color);
  border-color: var(--neutral-color);
  color: var(--font-color-light);
}

.asako-btn-secondary:hover {
  background: var(--neutral-color);
  border-color: var(--secondary-color);
  color: var(--secondary-color);
  transition: all 0.5s;
}

.asako-btn-warning {
  background: var(--warning-color);
  color: var(--font-color);
}

.asako-btn-danger {
  background: var(--danger-color);
  color: var(--font-color-light);
  &:not(:disabled):hover {
    background: var(--neutral-color);
    border-color: var(--danger-color);
    color: var(--danger-color);
    transition: all 0.5s;
  }
  &:disabled {
    opacity: 0.7;
    background-color: var(--danger-color-disabled);
  }
}

.asako-btn-group {
  border-radius: 5px;
  overflow: hidden;
  width: min-content;
  height: min-content;
  
  .asako-btn-icon:hover {
    border: -2px;
  }

  .asako-btn-icon {
    border-radius: 0px;
    border: 0px;
  }

}

.asako__popup {
  .modal-header {
    background: var(--primary-color);
    color: var(--font-color-light);
    box-shadow: none;
    i {
        position: absolute;
        right: 20px;
        top: 10px;
        font-size: x-large;
        color: var(--font-color-light);
    }
    i:hover {
        color: var(--font-color-gray);
    }
}
  .modal-body {
    background-color: white;
    position: relative;
    span {
      color: var(--primary-color);
    }
    i {
      position: absolute;
      right: 2px;
      top: 0px;
      font-size: x-large;
    }
    i:hover {
      color: var(--font-color-gray);
    }
  }
}

.asako__modal {
  .header, .modal-header {
      background: var(--primary-color);
      color: var(--font-color-light);
      box-shadow: none;
      i {
          position: absolute;
          right: 20px;
          top: 10px;
          font-size: x-large;
          color: var(--font-color-light);
      }
      i:hover {
          color: var(--font-color-gray);
      }
  }

  .body, .modal-body {
    background-color: white;
    span {
      color: var(--primary-color);
    }
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  .page-item.active .page-link {
    background-color: var(--primary-color) !important; /* Couleur de l'élément actif */
    border-color: var(--primary-color) !important;
    color: white;
  }
  .page-link {
    color: var(--primary-color); /* Couleur des liens de pagination */
  }
  .page-link:hover {
    background-color: var(--primary-color-darker) !important; /* Couleur au survol */
    color: white;
  }
}

.purple-color {
  color: var(--primary-color);
}

// animation
@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}