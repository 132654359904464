.cash-movement-parameter{
        .card {
            .body {
                min-height: 600px;
                
            }

            .cash-movement-type {
                position: relative;
                table-layout: fixed;
                width: inherit;
                .action {
                    width: 5%;
                    text-align: end;
                }
                .name {
                    width: 65%;
                }
                .movement {
                    width: 15%;
                }
                .index {
                    width: 10%;
                    text-align: end;
                }
            }

            .cash-register {
                position: relative;
                table-layout: fixed;
                width: inherit;
                .action {
                    width: 5%;
                    text-align: end;
                }
                .name {
                    width: 65%;
                }
                .movement {
                    width: 15%;
                }
                .index {
                    width: 10%;
                    text-align: end;
                }
            }
    }
}