
.task-container {
    color: var(--font-color);
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: none;
    background: transparent;
    padding: 10px 10px;
    z-index: 5;
    
    .add-column-task {
        cursor: pointer;
        min-width: 300px;
        background-color: #111;
        height: 50px;
        display: flex;
        justify-content: center;
        font-weight: 700;
        align-items: center;
        color: var(--font-color-light);
        border-radius: 5px;
        background: rgba(17, 17, 17, 0.2); 
    }

    .add-column-task:hover {
        background: var(--primary-color);
        color: var(--font-color-light);
    }

    .task-column.skeleton-loader {
        background-color: white;
        position: relative;
        overflow: hidden;
        .title {
            height: 40px;
            width: 80%;
            background-color: var(--loader-gray-color);
            border-radius: 4px;
            position: relative;
            overflow: hidden;
        }

        .card {
            background-color: var(--loader-gray-color);
            margin-top: 10px;
            border-radius: 5px; 
            width: 100%;
            height: 100px;
        }
    }
    
    .task-column {
        min-width: 300px;
        margin-right: 30px;
        padding: 10px;
        height: fit-content;
        border-radius: 5px;
        box-shadow: 2px 2px 5px gray;
        background: white;
        position: relative;
        
        .header {
            background: inherit;
            color: #333333;
            box-shadow: none;
            .form-group-column {
                display: flex;
                justify-content: flex-start;
                width: 85%;
            }
            
            .column-setup {
                z-index: 20;
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                position:absolute;
                top: 10px;
                right: 15px;
                border-radius: 8px;
                cursor: pointer;
            }
            .column-setup:hover {
                background: var(--bg-button-task-hover);
            }
        }
        .body {

            display: block;
            max-height: 80vh;
            overflow-y: scroll;
            overflow-x: visible;
            padding: 5px;

            .task-card {
                box-sizing: border-box;
                position: relative;
                width: 100%;
                margin-bottom: 5px;
                background: var(--bg-task-card);
                border-radius: 5px;
                border: 2px var(--border-color) solid;
                cursor: pointer;
                .label-container {
                    display: flex;
                    justify-content: flex-start;
                    margin-bottom: 5px;
                    .label {
                        width: 40px;
                        border-radius: 10px;
                        height: 10px;
                        margin-right: 2px;
                    }
                }

                .header {
                    .cover {
                        width: 100%;
                        background-color: white;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    .state-icon {
                        background: white;
                        padding: 5px 5px;
                        width: 30px;
                        height: 30px;
                        top: 5px;
                        right: 9px;
                        position: absolute;
                        border-radius: 50%;
                        z-index: 2;
                        justify-content: center;
                        align-content: center;
                        i {
                            font-size: 90%;
                        }

                        .danger {
                            color: var(--danger-color);
                        }

                        .success {
                            color: var(--success-color);
                        }
                    }
                    .modify-icon{
                        background: white;
                        padding: 5px 5px;
                        width: 30px;
                        height: 30px;
                        top: 5px;
                        right: 9px;
                        position: absolute;
                        border-radius: 50%;
                        justify-content: center;
                        align-content: center;
                        i {
                            font-size: 90%;
                        }
                    }
                    .modify-icon:hover {
                        background: var(--bg-button-task-hover);
                    }

                }

                .task-card-content {
                    padding: 10px;
                    span {
                        margin-right: 10px;
                    }

                    h5 {
                        margin-bottom: 0px !important;
                    }
                }

                .content-icons {
                    position: absolute;
                    left: 5px;
                    bottom: 3px;
                }

                .due_datetime-badge {
                    position: absolute;
                    padding: 5px 10px;
                    right: 5px;
                    border: 2px solid transparent;
                    bottom: 5px;
                }

                .due_datetime-badge:hover {
                    z-index: 10;
                    border: 2px solid var(--primary-color);
                }
            }

            .task-card:hover {
                border: 2px var(--primary-color) solid;
                .header {
                    .modify-icon {
                        display: flex;
                    }
                    
                }
            }
        }

        .add-card {
            margin-top: 10px;
            padding: 5px;
            // background: var(--primary-color);
            // color: var(--font-color-light);
            // border: var(--primary-color) 2px solid;
            border-radius: 7px;
            text-align: center;
            cursor: pointer;
        }

        .add-card:hover {
            background: var(--bg-button-task-hover);
        }
    }
}

.task-card__modal {
    position: relative;

    .modal-body {
        min-height: 800px;
    }
    .close-button {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-size: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 10;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }
    .close-button:hover {
        background: var(--bg-button-task-hover);
    }

    .options {
        margin-top: 50px;
        display: grid;
        justify-content: right;
        align-items: flex-start;
        height: fit-content;
    }

    .group-action {
        margin-bottom: 10px;
        h5 {
            font-weight: 900;
            font-size: 110%;
        }
    }

    .action {
        position: relative;
        .button-action {
            width: 200px;
            height: 40px;
            margin-bottom: 5px;
            padding: 5px 10px;
            border: none;
            border-radius: 5px;
            text-align: left;
        }

        button:hover {
            background: var(--bg-button-task-hover);
        }

        .card {
            position: absolute;
            padding: 20px;
            box-shadow: 5px 5px 5px gray;
            z-index: 15;
        }

        .list-member {
            margin-top: 10px;
            h6 {
                font-weight: 700;
            }
        }

        .member-container {
            width: 300px;
            padding: 10px;
            .title {
                text-align: center;
            }

            .input {
                margin-bottom: 5px;
            }

            .member-loader {
                border-radius: 5px;
                background-color: var(--loader-gray-color);
                width: 100%;
                height: 40px;
                margin-bottom: 5px;
            }

            .member {
                border-radius: 5px;
                padding-top: 3px;
                margin-bottom: 5px;
                height: 40px;
                position: relative;
                cursor: pointer;
                
                div {
                    padding: 5px
                }
                .name {
                    padding-left: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .img {
                    top: 2px;
                    left: 3px;
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                }
                .remove {
                    height: inherit;
                    position: absolute;
                    right: 10px;
                }
            }

            .member:hover {
                background: var(--bg-button-task-hover);
            }
        }

        .member-action {
            .close {
                cursor: pointer;
                position: absolute;
                top: 10px;
                right: 10px;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .close:hover {
                background: var(--bg-button-task-hover);
            }

            

        }
    }

    .item {

        position: relative;
        padding-left: 30px;
        margin-bottom: 10px;

        .card-label {
            display: flex;
            justify-content: flex-start;
            gap: 5px;
            position: relative;
            height: 34px;
            .label {
                padding: 5px 10px;
                border-radius: 5px;
            }

            .add {
                background: var(--bg-button-task-hover);
                cursor: pointer;
            }
        }
        
        .item-icon {
            position: absolute;
            top: 0px;
            left: 0px;
            font-size: 140%;

        }

        .edit-icon {
            position: absolute;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0px;
            right: 0px;
            border-radius: 50%;
        }

        .edit-icon:hover {
            background: var(--bg-button-task-hover);
        }

        
    
    }

}

.attachment-container-info {
    .attachments {
        display: grid;
        justify-content: flex-start;
        gap: 10px;
        max-height: 200px;
        padding: 10px;
        overflow-y: scroll;
        overflow-x: hidden;
        .attachment {
            display: flex;
            justify-content: flex-start;
            padding: 5px;
            width: 700px;
            border-radius: 5px;
            box-shadow: 1px 1px 3px gray;
            align-items: center;
            gap: 10px;
            background-color: whitesmoke;
            .cover {
                height: 60px;
                min-width: 70px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px var(--border-color) solid;
                border-radius: 5px;
                font-size: 6pt;
            
                img {
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                }
            }
    
            .content {
                display: block;
                align-items: flex-start;
                padding: 5px;
                min-width: 700px;
                height: fit-content;
                ul {
                    list-style: none;
                    .title {
                        font-size: 13pt;
                        margin-bottom: -5px;
                    }
        
                    .date {
                        font-size: 9pt;
                    }

                    .action {
                        font-size: 9pt;
                        color: blue;
                        cursor: pointer;

                        span.disabled {
                            color: gray;
                        }

                        span.disabled:hover {
                            text-decoration: none;
                        }

                        span:hover {
                            text-decoration: underline;
                        }
                    }

                }
            }
        }
    }
}

.label-modal {
    position: relative;
}

.option-container {
    position: absolute;
    background: white;
    border-radius: 5px;
    z-index: 10;
    box-shadow: 2px 2px 5px gray;
    .title {
        padding: 10px;
        font-weight: 700;
        text-align: center;
        border-bottom: var(--bg-button-task-hover) 1px solid;
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .content {
        padding: 10px;
    }
}

.attachment-container {
    width: 250px;
    .form-group {
        label {
            display: block;
            width: 100%;
            padding: 5px 10px; 
            margin: 0 auto;
            color: white;
            background-color:var(--primary-color);
            border:1px white solid;
            border-radius: 5px;
            font-size: 1em;
            line-height: 2.5em;
            text-align: center;
        }
        
        label:hover {
            border-color: var(--primary-color);
            color: var(--primary-color);
            background-color: white;
        }
        
        label:active {
            background-color: white;
        }
        
        input {
            border: 0;
            clip: rect(1px, 1px, 1px, 1px);
            height: 1px; 
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
    }
}

.date-container {
    .date-group {
        margin-bottom: 10px;
        label {
            width: 100%;
        }
        .group-input {  
            display: flex;
            justify-content: flex-start;
            gap: 10px;

            .checkbox-container {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            
            input {
                padding: 5px;
                padding-right: 10px;
            }

            input:disabled {
                background: var(--bg-button-task-hover);
            }

            input[type="checkbox"] {
                width: 20px;
                height: 20px;
                border-radius: 5px;
                border: 1px solid var(--bg-button-task-hover);
                cursor: pointer;
                appearance: none; 
                background-color: white; 
                transition: background-color 0.2s ease, border-color 0.2s ease;
            }
            
            input[type="checkbox"]:checked {
                background-color: var(--primary-color);
                border-color: var(--primary-color);
                position: relative;
            }
            
            input[type="checkbox"]:checked::after {
                content: '\2713'; 
                color: white;
                font-size: 20px;
                position: absolute;
                top: -6px;
                left: 2px;
            }

            input[type="text"], input[type="time"] {
                border-radius: 5px;
                border: 1px var(--bg-button-task-hover) solid;
            }

            input[type="text"]:focus, input[type="time"]:focus {
                border: 2px var(--primary-color) solid;
            }
        }
    }
}

.labels-container {
    width: 250px;
    .search {
        margin-bottom: 10px;
    }

    .new-label {
        font-size: 80%;
        width: 100%;
        text-align: center;
        padding-block: 5px;
        border-radius: 5px;
    }

    .label {
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        .input {
            height: 30px;
            width: 30px;
            color: var(--primary-color);
            display: flex;
            align-items: center;

            input[type="checkbox"] {
                width: 15px;
                height: 15px;
                background-color: white;
                border: 2px solid var(--bg-button-task-hover);
                cursor: pointer;
            }

            input[type="checkbox"]:checked {
                background-color: var(--primary-color);
                color: white;
            }
        }
        .color {
            .value {
                width: 180px;
                height: 30px;
                border-radius: 5px;
                padding-left: 10px;
                padding-top: 2px;
            }
        }
        .modify {
            width: 30px;
            height: 30px;
            padding: 10px 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
        }
        .modify:hover {
            background: var(--bg-button-task-hover);
        }
    }
}

.move-container {
    label {
        width: 100%;
    }

    select {
        background: white;
        height: 35px;
        border: var(--bg-button-task-hover) 1px solid;
        border-radius: 5px;
        padding: 5px;
    }

    button {
        width: 100%;
    }

    .list {
        width: 250px;
    }

    .position {
        width: 100px;
    }
}

.task-card__modal {
    .body {
        background-color: white;
    }
    .add-description {
        background: var(--bg-task-column);
        width: 100%;
        height: fit-content;
        padding: 10px;
        cursor: pointer;
        border-radius: 5px;
    }
    .add-description:hover {
        border: 1px var(--primary-color) solid;
    }
    .add-description-editor {
        background-color: white;
    }
}

.task-colonne__loader {
    color: var(--font-color);
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
    max-height: 80vh;
    overflow-x: auto;
    background: transparent;
    padding: 10px 10px;

}

.info-card__comment {
    .comment-wrapper {
        margin-bottom: 10px;
        position: relative;
        height: fit-content;
        display: grid;
        align-items: center;
        .user-avatar-wrapper  {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            position: absolute;
            top: 0px;
            left: -47px;
            
            .user-avatar {
                width: 40px;
                height: 40px;
                border: 1px var(--bg-button-task-hover) solid;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 2rem;
            }
        }

        .comment-content {
            .comment-header {
                font-size: 0.8rem;
                .comment-author {
                    font-weight: 600;
                }

            }

            .comment-text {
                border-radius: 5px;
                box-shadow: 1px 1px 3px gray;
                display: flex;
                align-items: center;
                background: whitesmoke;
                padding: 7px 10px;
                font-size: 1rem;
                width: 100%;
            }

            .action {
                color: blue;
                font-size: 9pt;
                cursor: pointer;
                span:hover {
                    text-decoration: underline;
                }
            }
        }

        .add-comment {
            height: 40px;
            width: 100%;
            background: white;
            border-radius: 5px;
            padding: 7px 10px;
            border: 1px var(--border-color) solid;
        }
        .add-comment:hover {
            border: 1px var(--primary-color) solid;
        }
    
        .editor {
            background: white;
            .ql-container {
                min-height: 60px;
            }
        }
    }
}

.column-option-container {
    ul {
        list-style: none;
        padding-left: 0;
        .item {
            padding: 5px 20px;
            height: 30px;
            min-width: 200px;
            margin-bottom: 2px;
            display: flex;
            align-items: center;
        }

        .item:hover {
            background-color: var(--bg-button-task-hover);
        }
    }
}

.option-modal {
    background-color: white;
    z-index: 1000;
    position: absolute;
    top: 40px;
    right: -155px;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 2px 2px 5px gray;
     
    .header {
        display: flex;
        padding: 5px;
        justify-content: center;
        position: relative;
        .close-icon {
            position: absolute;
            top: 7px;
            right: 7px;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .close-icon:hover {
            background: var(--bg-button-task-hover);
        }
    }

    hr {
        margin-block: 0px;
        width: 50%;
    }
}

.move-column-option-container {
    width: 200px;
    padding: 20px;

    .form-group {
        label {
            width: 100%;
        }

        select {
            width: 100%;
            padding: 10px 10px;
            background-color: white;
            border: 1px solid var(--border-color);
            border-radius: 5px;
        }

    }
    button[type="submit"] {
        padding: 5px 10px;
        border-radius: 5px;
        border: 1px solid white;
    }

    button[type="submit"]:hover {
        padding: 5px 10px;
        border-radius: 5px;
        border: 1px solid var(--primary-color);
    }
}

.option-modal.card-option {
    z-index: 45;
    position: static;
}