.projects-container {

    .project {
        &.loader {
            div {
                background: var(--loader-gray-color);
                border-radius: 5px;
            }
            .card-header {
                div {
                    width: 75%;
                    height: 31px;
                }
            }

            .card-body {
                display: grid;
                align-content: flex-start;
                gap: 5px;
                div {
                    width: 100%;
                    height: 30px;
                }
                div:last-child {
                    width: 25%;
                }
            }
        }

        &.card {
            cursor: pointer;
            max-width: 500px;
            .card-header {
                display: flex;
                align-items: center;
                min-height: 65px;
                background-color: white;
            }
    
            .card-body {
                width: 100%;
                height: 200px;
                background-color: white;
                overflow: scroll;
            }
    
            .card-footer {
                cursor: auto;
                min-height: 65px;
                background-color: white;
                display: flex;
                justify-content: flex-end;
            }
        }

        &.card:hover {
            transform: scale(1.1);
            transition: all 0.3s ease-in-out;
        }
    }
}

.project-form {
    .description-form {
        .quill {
            background-color: white;
            .ql-container {
                min-height: 100px;
            }
        }
    }
}



.projects-container {
    h1 {
        text-align: center;
        span {
            color: var(--primary-color);
        }
        
    }

    h3 {
        span {
            font-weight: 700;
            color: var(--primary-color);
        }
    }

    .project__card {
        cursor: pointer;
        width: 400px;

        .card-header, .card-footer {
            background: white;
        }
    }

}

.project__form--group {
    width: 100%;
}

.project__form--label {
    width: 100%;
}

.project__form--input {
    width: 100%;
    border-radius: 5px;
    font-size: 1rem;
}


.project__modal {
    max-width: 600px;
    border: none;
}

.project__modal--header {
    background-color: var(--primary-color);
    color: var(--font-color-light);
    justify-content: center;
}

.project__modal--body {
    span {
        color: var(--danger-color);
    }
}

.project__modal--button {

    .project__modal--closing {
        background: var(--primary-color);
    }

}
