
.basic-navbar-nav{
    border-color: var(--primary-color);

    .navbar-toggler-icon {
        color: var(--primary-color);
    }
}
.home-header {
    left: 0 !important;
    background: white;
    box-shadow: 1px 1px 2px gray;
    animation: fadeIn 2000ms ease-in-out forwards;
    .toggler {
        border-color: red;
        color: red;
        span {
            color: red;
        }
    }
    
    .menu-right{
        justify-content: flex-end;
        .menu-right-item {
            display: flex;
            align-items: center;
            .link {
                color: black;
            }
            .link.active {
                color: black;
                font-weight: 500;
            }
            .link:hover {
                color: var(--primary-color);
                transition: color 0.3s;
            }
            .btn-connect {
                background: var(--primary-color);
                color: white;
                border-color: white;
                border-radius: 7px;
                padding: 7px 24px;
                font-weight: 500;
                font-size: 15px;
            }
            .btn-connect:hover {
                background: white;
                color: var(--primary-color);
                border-color: var(--primary-color);
                transition: all 0.5s;
            }
        }
    }
}
