.sign__container {
    display: flex;
    justify-content: space-between;
}

.sign__image {
    background-color: var(--primary-color);
    width: 40%;
    height: 100vh;
    overflow: hidden;
}

.sign__image img {
    width: 100%;
    height: auto;
}

.sign__form--container {
    width: 60%;
    height: 100vh;
    text-align: center;
    padding: 20vh 10%;
    background: var(--bs-light);
}

.sign__form--container h1 {
    color: var(--primary-color);
}

.sign__form--input {
    margin-bottom: 20px;
    position: relative;
    --bs-border-color: var(--primary-color);
}

.sign__form--input:hover {
    border-color: var(--primary-color);
}

.sign__submit {
    width: 100%;
    height: 40px;
    background: var(--primary-color);
    border: none;
    border-radius: 5px;
    color: var(--font-color-light);
}

.sign__submit:hover {
    background: var(--primary-color-darker);
    transform: scale(1.01);
    transition: all ease-in-out 100ms;
}
