.order-detail-container {
    .card {
        .card-header {
            background: white;
            display: flex;
            justify-content: space-between;
        }

        .description {
            p, ul {
                margin-bottom: 0;
            }
        }
        
        &.order-info-card {
            .card-body {
                ul {
                    list-style: none;
                }
    
            }
        }
    }

    .badge {
        padding: 5px 15px;
    }

    .order-status-select {
        border: 1px solid var(--border-color);
        border-radius: 20px;
        padding: 5px 10px;
        font-size: 75%;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
    }
}

