.step-supplier-information {
    button {
        position: relative;
        right: 0;
    }

}

.supplier-information {
    .form-notices {
        ul {
            list-style: circle;
            li {
                display: flex;
                justify-content: space-between;
                padding: 5px 10px;
                margin-bottom: 2px !important;
                border-radius: 5px;
                span {
                    padding-inline: 10px;
                }
                i {
                    color: var(--danger-color);
                    cursor: pointer;
                }
            }

            li:hover {
                background-color: var(--border-color);
            }
        }
    }
    .quill {
        .ql-editor {
            height: 100px;
        }
    }
}

.supplier__wizard {
    .step {
        color: var(--primary-color);
    }
    .--footer {
        justify-content: end;
    }

}

.product-table {

    .name {
        width: 30%;
    }

    .unit_price {
        text-align: right;
        width: 20%;
    }

    .description {
        width: 30%;
        ul, p {
            margin-bottom: 0;
        }
    }

    .notices {
        ul {
            margin-bottom: 0;
        }
    }

    .action-icons {
        display: flex;
        justify-content: center;
        gap: 10px;
        .notices-button, .remove {
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
        }

        .notices-button {
            background-color: transparent;
            border: 2px solid var(--primary-color);
            color: var(--primary-color);
        }
        .notices-button:hover {
            background-color: var(--primary-color);
            border: 2px solid white;
            color: white;
        }
        .remove {
            background-color: transparent;
            border: 2px solid var(--danger-color);
            color: var(--danger-color);
        }
        .remove:hover {
            background-color: var(--danger-color);
            border: 2px solid white;
            color: white;
        }
    }
}

.recap-step-add-supplier-container {
    .description {
        ul, p {
            margin-bottom: 0;
        }
    }
}