
.suppliers-container {
    .description {
        span {
            color: var(--primary-color);
            font-weight: 500;
        }
    }
}

.supplier__card {
    .card-body {
        min-height: 340px;
    }
    &.loader {
        .card-body {
            display: grid;
            align-content: flex-start;
            gap: 5px;
            div {
                border-radius: 5px;
                background-color: var(--loader-gray-color);
            }
            .title {
                margin-bottom: 15px;
                height: 30px;
                width: 100%;
            }

            .info-title {
                height: 27px;
                width: 80%;
            }

            .info {
                height: 27px;
                width: 50%;
            }
        }
    }

    color: var(--font-color);
    a {
        color: var(--font-color);
    }

    a:hover {
        text-decoration: none;
        color: var(--font-color);
    }

    .title {
        h4 {
            color: var(--primary-color);
            font-size: 25px;
        }
    }
    .--information {
       height: 200px; 
       overflow-y: scroll;
    }


}

.supplier__card:hover {
    transform: scale(1.1);
    transition: all 0.3s;
}