.contact-container {
    height: calc(100vh - 50px);
    padding-top: 50px;
    position: relative;
    display: grid;
    justify-content: center;
    background-color: var(--primary-color);
    color: var(--font-color-light);

    display: flex;

    .card {
        position: absolute;
        top: 15%;
        padding: 50px;
        color: var(--font-color);
        border-radius: 20px;
        min-width: 200px;
        width: 80vw;
        height: fit-content;
        .title {
            text-align: center;
            h3 {
                span {
                    color: var(--primary-color);
                }
                margin-bottom: 50px;
            }
        }

        .nav-tabs {
            justify-content: flex-start;

            .nav-link.active {
                text-decoration: underline;
            }
            
        }
    }
}

.tab-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    .caption {
        height: 370px;
        img {
            width: 100%; 
            height: 100%;
            aspect-ratio: 1 / 1; 
            object-fit: cover; 
        }
    }

    .mail {
        flex: 1;
        .question-editor {
            &.invalid {
                .ql-container, .ql-toolbar {
                    border: solid var(--danger-color) 1px;
                }
            }
            
            .ql-toolbar {
                border-top-left-radius: 5px;   
                border-top-right-radius: 5px;
            }

            .ql-container {
                height: 150px;
                border-bottom-left-radius: 5px;   
                border-bottom-right-radius: 5px;
            }
        }

        .submit-group {
            display: flex;
            justify-content: center;
        }
        button[type="submit"] {
            padding: 5px 100px;
        }
    }

    .phone {
        display: grid;
        justify-content: center;
        flex: 1;
        p{
            font-size: 19px;
        }
        ul {
            list-style: none;
            padding-left: 25px;
            li {
                font-size: 18px;
                .bi{
                    margin-right: 15px;
                }
                .bi-facebook{
                    color: blue;
                }
                .bi-envelope-at-fill{
                    color: #e63030;
                }
            }
        }
    }
}




