.supplier-detail__card-title {
    display: flex;
    justify-content: center;
    color: var(--primary-color);

}

.supplier__product-card {
    width: 100%;
}


.form-notices {
    ul {
        list-style: circle;
        li {
            display: flex;
            justify-content: space-between;
            padding: 5px 10px;
            margin-bottom: 2px !important;
            border-radius: 5px;
            span {
                padding-inline: 10px;
            }
            i {
                color: var(--danger-color);
                cursor: pointer;
                order: 2;
            }
        }

        li:hover {
            background-color: var(--border-color);
        }
    }
}

.product-quill {
    background-color: white;
    .ql-container {
        min-height: 100px;
        max-height: 300px;
        overflow: scroll;
    }

}
.supplier__product-table {
    border-collapse: collapse;
    width: inherit;

    .global_information {
        cursor: pointer;
    }
    
    th, td{
        max-width: 100px;
        padding: 5px;
        height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .name {
        cursor: pointer;
        width: 20%;
    }

    .description {
        cursor: pointer;
        width: 40%;
    }

    .unit_price {
        cursor: pointer;
        width: 20%;
        text-align: right;
    }

    .notice {
        cursor: pointer;
        width: 30%;
    }

    .action {
        width: 5%;
        padding: 5px;
    }

}

.deleting {
    .product-deleted {
        color: var(--danger-color);
    }

    .supplier {
        color: var(--primary-color);
    }
}


.add-supplier-card {
    text-align: center;
    cursor: pointer;
    .asako-btn-primary {
        border-radius: 5px;
        &:hover {
            text-decoration: none;
        }
    }
}

.add-supplier-link {
    a:hover {
        text-decoration: none;
    }
}

.add-supplier-card:hover {
    transform: scale(1.02);
    transition: 0.1s all ease-in-out;
    text-decoration: none;
}