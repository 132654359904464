.login__container {
    display: flex;
    justify-content: space-between;
    background: white;
    .home {
        position: fixed;
        top: 15px;
        right: 50px;
        font-size: 19px;
        color: var(--primary-color);
        z-index: 1;
    }
}

.login__image {
    width: 60%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login__image img {
    width: 80%;
    height: auto;
    animation: fadeIn 1000ms ease-in-out forwards;
}

.login__form--container {
    width: 40%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: var(--bs-light);
    animation: fadeIn 700ms ease-in-out forwards;
    .parent{
        border: 1px solid var(--border-color);
        border-radius: 7px;
        padding: 25px 20px;
        h2{
            font-size: 23px;
            font-weight: 500;        
        }
        label {
            width: 100%;
            text-align: start;
            font-size: 13px;
            font-weight: 500;
            i {
                margin-right: 7px;        
            }
        }
        .forgotPassword{
            margin-top: 15px;
            a {
                color: var(--primary-color);
            }
        }
        .nousContacter {
            span {
                color: var(--primary-color);
                font-weight: 500;  
            }
        }
    }
}

.login__form--input {
    margin-bottom: 20px;
    position: relative;
    --bs-border-color: var(--primary-color);
}

.login__form--input:hover {
    border-color: var(--primary-color);
}

.login__submit {
    width: 100%;
    height: 40px;
    background: var(--primary-color);
    border: none;
    border-radius: 5px;
    color: var(--font-color-light);

    &:disabled {
        background: var(--primary-color-disabled);
        opacity: 0.7;
        cursor: not-allowed;
    }

    &:not(:disabled):hover {
        background: white;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        transform: scale(1.01);
        transition: all ease-in-out 300ms;
    }
}