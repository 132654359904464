.cash-movement-widget__card {
    .body {
        height: 150px;
        h5 {
            font-size: 2rem;
        }
    }
}

.cash-movement-filter__card {
    h4 {
        cursor: pointer;
    }
    .body {
        max-height: 0; 
        opacity: 1; 
        transition: max-height 1s ease-out, opacity 0.2s, padding 1s;

    }
    .body.show {
        max-height: 500px; 
        opacity: 1;
    }
    .body.hide {
        max-height: 0;
        opacity: 0;
        padding: 0;
    }
}


.cash-register-flow__card {
    height: 500px;
    table {
        tr {
            .balance {
                text-align: end;
            }
        }
    }
}

.cash-movement__card {
    .body {
        .cash-movement__table {
            position: relative;
            table-layout: fixed;
            max-width: inherit;
            tr {
                width: inherit;
            }
            td, th {
                overflow: hidden;
                text-overflow: ellipsis;
            }
            td {
                padding: 10px 15px 2px 15px;
                height: 50px;
            }
            .input-line {
                td {
                    padding: 2px;
                }
                .action-input {
                    padding: 12px 15px;
                }
                .input {
                    height: 50px;
                }
            }
            .movement_date {
                width: 8%;
                text-align: end;
            }
            .responsable {
                width: 8%;
            }
            .cash_register {
                width: 10%;
            }
            .type {
                width: 7%;
            }
            .product {
                width: 20%;
            }
            .supplier {
                width: 8%;
            }
            .quantity {
                width: 4%;
                text-align: end;
            }
            .unit_price {
                width: 8%;
                text-align: end;
            }
            .total_inflow {
                width: 10%;
                text-align: end;
            }
            .total_outflow {
                width: 10%;
                text-align: end;
            }
            .action {
                width: 5%;
                padding: 12px 15px !important;
            }
        }
    }
}
