.new-order-container {
    .product-card.card {
        .card-header {
            display: flex;
            justify-content: space-between;
        }
    }
    
    
    .card {
        .card-header {
            background: white;
        }
    }
}

$action-width: 100px;
$product-width: 10%;
$quantity-width: 20%;
$detail-width: 50%;
$total-width: 20%;

.new-order-table {
    tbody {
        .action {
            width: $action-width;
        }

        .product {
            width: $product-width;
        }

        .quantity {
            width: $quantity-width;
        }

        .details {
            width: $detail-width;
            ul, p {
                margin-bottom: 0;
            }
        }

        .total {
            width: $total-width;
        }
    }
}