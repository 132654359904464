.order-card {
    .card-header {
        background-color: white;
        display: flex;
        justify-content: space-between;
    }

    .card-body {
        padding-bottom: 100px;
        td.description{
            ul, p {
                margin-bottom: 0;
            }
        }

        td.is-paid {
            .badge {
                cursor: pointer;
                z-index: 100;
            }
        }
    }


    .badge {
        padding: 5px 15px;
    }
}